export const DEFAULT_PAGE_SIZE = 50;
export const MIN_YEAR = 2024;
export const MAX_FUTURE_YEARS = 1;

export const USER_TYPES = {
  BO: "백오피스",
  APP: "사용자",
};

export const USER_STATUS = {
  10: "정상",
  40: "잠김",
  60: "탈퇴",
};

export function flatten<T extends ITree<T>>(array?: T[]): T[] {
  if (!array) return [];
  const results: T[] = [];
  results.push(...array);
  array.forEach((node) => {
    const r: T[] = flatten(node.children);
    results.push(...r);
  });
  return results;
}
